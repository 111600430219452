
import { defineComponent, ref, computed } from 'vue'

export default defineComponent({
  props: {
    height: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    responsive: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isFilled = ref(false)

    const style = computed(() => ({
      width: `${props.width}px`,
      height: `${props.height}px`,
    }))

    const label = computed(() => `${props.width}x${props.height}`)

    return {
      isFilled,
      style,
      label,
    }
  },
})
