<template>
  <InfoBox>
    <header>
      <h3 class="item-title" v-text="adItem?.title"/>
      <BackButton :slug="adItem.type.slug"/>
    </header>

    <div class="item-description" v-html="description"/>
    <AvailableFormats :formats="adItem.formats" v-if="adItem.formats" :description="adItem.formatsLead"/>
  </InfoBox>
  <main class="main-content">
    <AdPlaceholder
      :width="adSize.width"
      :height="adSize.height"
      class="banner"
      responsive/>
    <ArticlesGrid :nb="6"/>
  </main>

  <Wrapper
    :width="wrapperSize.width"
    :height="wrapperSize.height"
    center
    class="background">
    <AdPlaceholder :width="1920" :height="1080" responsive>
      Wallpaper | Assets: GIF, JPG, PNG
    </AdPlaceholder>
  </Wrapper>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount } from 'vue'
import useItemSizes from '@/composables/useAdItem'

import Wrapper from '@/components/Wrapper.vue'
import InfoBox from '@/components/InfoBox.vue'
import AdPlaceholder from '@/components/AdPlaceholder.vue'
import BackButton from '@/components/BackButton.vue'
import AvailableFormats from '@/components/AvailableFormats.vue'
import ArticlesGrid from '@/components/ArticlesGrid.vue'

export default defineComponent({
  components: {
    ArticlesGrid, Wrapper, AdPlaceholder, InfoBox, BackButton, AvailableFormats,
  },
  props: {
    adItem: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const {
      changeSizeTo, wrapperSize, adSize, getBiggestAdSize, description,
    } = useItemSizes(props.adItem)

    onBeforeMount(() => {
      changeSizeTo(getBiggestAdSize())
    })

    return {
      wrapperSize,
      adSize,
      description,
      changeSizeTo,
    }
  },
})
</script>
<style lang="scss" scoped>
.background {
  @apply fixed left-0 right-0 top-0 bottom-0;
}

.main-content {
  @apply absolute z-20 text-center;
  left: 50%;
  width: 75rem;
  transform: translateX(-50%);
}

.banner {
  @apply shadow-lg my-12;
}
</style>
