
import { defineComponent, computed, onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'

import useTypes from '@/composables/useTypes'
import useAdItems from '@/composables/useAdItems'
import AddItemListItem from '@/components/AddItemListItem.vue'

export default defineComponent({
  components: {
    AddItemListItem,
  },
  setup() {
    const route = useRoute()
    const { slug } = route.params
    const { findOne, fetch } = useTypes()
    const { fetchAdItems, adItems } = useAdItems()

    onBeforeMount(() => {
      fetch()
      fetchAdItems(slug)
    })

    const type = computed(() => findOne(slug))

    return {
      type,
      adItems,
    }
  },
})
