import axios from 'axios'

export default function () {
  const api = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:1337/' : 'https://ads.antyweb.ably.pl/api',
  })

  return {
    api,
  }
}
