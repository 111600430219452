
import { defineComponent, ref } from 'vue'
import Hamburger from '@/components/Hamburger.vue'

export default defineComponent({
  components: { Hamburger },
  setup() {
    const isHidden = ref(false)

    const handleToggle = (state: boolean) => {
      isHidden.value = !state
    }

    return {
      isHidden,
      handleToggle,
    }
  },
})
