import get from 'lodash.get'
import { computed, ref } from 'vue'
import { AdSize } from '@/types'
import snarkdown from 'snarkdown'

export default function (adItem: any) {
  const activeSize = ref(0)

  const wrapperSize = computed(() => ({
    width: get(adItem, 'window.width', null),
    height: get(adItem, 'window.height', null),
  }))

  const changeSizeTo = (size: AdSize) => {
    const { sizes } = adItem || {}
    activeSize.value = sizes.indexOf(size)
  }

  const adSize = computed(() => {
    const { sizes } = adItem || {}

    if (!sizes) {
      return {
        width: 1200,
        height: 400,
      }
    }

    const { width, height } = sizes[activeSize.value]

    return {
      width,
      height,
    }
  })

  const getBiggestAdSize = (): AdSize => {
    const { sizes } = adItem || {}

    return sizes.reduce((prev: AdSize, current: AdSize) => {
      if (prev.width > current.width) {
        return prev
      }
      return current
    }, { width: null, height: null })
  }

  const description = computed(() => {
    if (!adItem?.description) {
      return ''
    }

    return snarkdown(adItem?.description)
  })

  return {
    activeSize,
    changeSizeTo,
    wrapperSize,
    adSize,
    getBiggestAdSize,
    description,
  }
}
