
import { defineComponent, onBeforeMount } from 'vue'
import useItemSizes from '@/composables/useAdItem'

import Wrapper from '@/components/Wrapper.vue'
import InfoBox from '@/components/InfoBox.vue'
import AdPlaceholder from '@/components/AdPlaceholder.vue'
import BackButton from '@/components/BackButton.vue'
import AvailableFormats from '@/components/AvailableFormats.vue'
import ArticlesGrid from '@/components/ArticlesGrid.vue'

export default defineComponent({
  components: {
    ArticlesGrid, Wrapper, AdPlaceholder, InfoBox, BackButton, AvailableFormats,
  },
  props: {
    adItem: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const {
      changeSizeTo, wrapperSize, adSize, getBiggestAdSize, description,
    } = useItemSizes(props.adItem)

    onBeforeMount(() => {
      changeSizeTo(getBiggestAdSize())
    })

    return {
      wrapperSize,
      adSize,
      description,
      changeSizeTo,
    }
  },
})
