<template>
  <div class="type">
    <strong v-if="type" class="label" v-text="type.label" />
    <h1 v-if="type" v-text="type.title"></h1>
    <div class="items">
      <AddItemListItem v-for="item in adItems" :key="item.id" :item="item"/>
    </div>
    <router-link class="back-link" to="/">Go back to list</router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'

import useTypes from '@/composables/useTypes'
import useAdItems from '@/composables/useAdItems'
import AddItemListItem from '@/components/AddItemListItem.vue'

export default defineComponent({
  components: {
    AddItemListItem,
  },
  setup() {
    const route = useRoute()
    const { slug } = route.params
    const { findOne, fetch } = useTypes()
    const { fetchAdItems, adItems } = useAdItems()

    onBeforeMount(() => {
      fetch()
      fetchAdItems(slug)
    })

    const type = computed(() => findOne(slug))

    return {
      type,
      adItems,
    }
  },
})
</script>

<style lang="scss" scoped>
.type {
  @apply flex flex-col items-center justify-center;
  height: 100vh;
}

.items {
  @apply pt-8 mb-10;

  display: grid;
  grid-gap: 1rem;
}

.back-link {
  @apply px-6 py-2 rounded-lg no-underline font-medium text-sm bg-gray-900 text-gray-600 ;
  transition: .5s ease-in-out all;

  &:hover {
    @apply bg-gray-700 text-gray-100;
  }
}
</style>
