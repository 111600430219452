
import { defineComponent, onMounted } from 'vue';

import useTypes from '@/composables/useTypes';
import TypeLink from '@/components/TypeLink.vue';
import Footer from '@/components/Footer.vue';

export default defineComponent({
  components: {
    TypeLink,
    Footer,
  },
  setup() {
    const { fetch, types } = useTypes();
    const isOdd = (index: number) => (index % 4) === 2 || (index % 4) === 3;

    onMounted(() => {
      fetch();
    });

    return {
      isOdd,
      types,
    };
  },
});
