<template>
  <footer class="footer">
    <div class="footer__container">
      <h2 class="footer-header">Interested in advertisement with Antyweb?</h2>
      <p class="footer-lead">
        <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-right"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 512"
            class="arrow">
          <path
              d="M224.3 273l-136 136c-9.4 9.4-24.6
              9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6
              0-33.9L54.3
               103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
              fill="currentColor"
          ></path>
        </svg>
        We have available slots for select
      </p>
      <a href="mailto:" class="cta">
        Send a Message</a>

    </div>
  </footer>
  <div class="credits">
    <div class="footer__container">
      &copy; 2020 Sebastian Szczepanski. All rights reserved.
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style lang="scss" scoped>
.footer {
  @apply border-t border-gray-800 text-left py-12;
  border-top-style: solid;

  &__container {
    @apply mx-auto max-w-6xl;
  }

  &-header {
    @apply text-4xl text-gray-400 tracking-tight leading-10 mb-2;
  }

  &-lead {
    @apply text-secondary text-2xl tracking-tight leading-9 font-bold my-0;
  }
}

.arrow {
  @apply text-gray-400 pr-1 text-xl w-2;
}

.credits {
  @apply p-4 text-gray-600 text-sm font-medium text-left;
  background: rgba(179, 230, 255, .1);
}

.cta {
  @apply bg-secondary  text-black font-semibold py-3 px-8 rounded-lg;
  @apply text-base inline-block no-underline mt-6;
}
</style>
