import { ref } from 'vue'
import useApi from '@/composables/useApi'

const types: any = ref([])

interface Type {
  id: number;
  label: string;
  title: string;
  ad_item: any;
  thumbnail: any;
  slug: string;
}

export default function () {
  const { api } = useApi()
  const fetch = async () => {
    if (types.value.length) {
      return types
    }

    const { data } = await api.get('/types')

    types.value = [...data]
    return data
  }

  const findOne = (slug: string|string[]) => types.value.find((type: Type) => type.slug === slug)

  return {
    fetch,
    types,
    findOne,
  }
}
