<template>
  <button
    type="button"
    class="hamburger"
    :class="{ 'hamburger--active': isActive }"
    @click.prevent="toggle"
  >
    <span class="hamburger__box">
      <span class="hamburger__inner" :class="{ 'hamburger__inner--active': isActive }"/>
    </span>
  </button>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  emits: ['toggle'],
  props: {
    active: Boolean,
  },
  setup(props, { emit }) {
    const isActive = ref(props.active)
    const toggle = () => {
      isActive.value = !isActive.value
      emit('toggle', isActive.value)
    }
    return {
      isActive,
      toggle,
    }
  },
})
</script>

<style lang="scss" scoped>
.hamburger {
  @apply cursor-pointer w-8 h-6 border-0 outline-none p-2 bg-transparent;
  box-sizing: content-box;

  &:focus {
    @apply outline-none;
  }

  &__box {
    @apply relative h-6 flex flex-col justify-center;
  }

  &__inner {
    @apply h-1 w-8 block bg-gray-600 rounded;
    transition-duration: 0.275s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);

    &:before {
      @apply h-1 w-8 block bg-gray-600 absolute top-0 rounded;
      content: "";
      transition: opacity 0.125s 0.275s ease;
    }

    &:after {
      @apply h-1 w-8 block bg-gray-600 absolute bottom-0 rounded;
      content: "";
      transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    &--active {
      $y-offset: 10px;
      transform: translate3d(0, 0, 0) rotate(135deg);
      transition-delay: 0.075s;

      &:before {
        transition-delay: 0s;
        opacity: 0;
      }

      &:after {
        transform: translate3d(0, 0, 0) rotate(90deg);
        transition-delay: 0.075s;
      }
    }
  }
}

</style>
