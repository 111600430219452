<template>
  <router-link
      :to="{ name: 'Type', params: {slug: type.slug} }"
      class="item"
      :class="{ 'item--odd': odd }"
  >
    <span class="item__label" :class="{ 'item__label--odd': odd }" v-text="type.label"/>
    <strong class="item__title" :class="{ 'item__title--odd': odd }" v-text="type.title"/>
    <img
        v-if="image"
        class="item__image"
        :src="image"
        :alt="type.title">
  </router-link>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
  props: {
    type: {
      type: Object,
      required: true,
    },
    odd: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const image = computed(() => `https://ads.antyweb.ably.pl/api/${props.type.thumbnail.formats.small.url}`);

    return {
      image,
    };
  },
});

</script>
<style lang="scss" scoped>
.item {
  @apply relative rounded-lg overflow-hidden cursor-pointer p-8 text-left no-underline relative;
  background-color: rgba(179, 230, 255, .1);
  min-height: 350px !important;
  transition: .3s ease all;

  &:hover {
    transform: scale(1.05);
  }

  &__label {
    @apply uppercase text-base font-bold tracking-wider mb-2 block;
    color: rgba(255, 255, 255, .5);

    &--odd {
      color: rgba(0, 0, 0, .25)
    }
  }

  &__title {
    @apply font-black text-3xl leading-tight block text-white;

    &--odd {
      color: rgb(14.28, 20.4, 26.520000000000003)
    }
  }

  &__image {
    @apply rounded-lg absolute;
    left: 5rem;
    top: 9rem;
  }

  &--odd {
    @apply bg-secondary;
  }
}
</style>
