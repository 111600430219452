
import { defineComponent } from 'vue'
import Article from '@/components/Article.vue'

export default defineComponent({
  components: {
    Article,
  },
  props: {
    nb: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {}
  },
})
