import useApi from '@/composables/useApi'
import { ref } from 'vue'

const adItems: any = ref([])
const adItem: any = ref(null)

export default function () {
  const { api } = useApi()

  const fetchAdItems = async (slug: string | string[]) => {
    const { data } = await api.get(`/ad-items?type.slug=${slug}`)
    adItems.value = [...data]

    return data
  }

  const findOne = async (slug: string | string[]) => {
    const { data } = await api.get(`/ad-items?slug=${slug}&_limit=1`)
    const [item] = data
    adItem.value = item

    return data
  }

  const findOneByType = async (type: string|string[], slug: string|string[]) => {
    const { data } = await api.get(`/ad-items?slug=${slug}&type.slug=${type}&_limit=1`)

    const [item] = data
    adItem.value = item

    return data
  }

  return {
    fetchAdItems,
    findOne,
    findOneByType,
    adItems,
    adItem,
  }
}
