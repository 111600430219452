
import { defineComponent, computed } from 'vue'
import snarkdown from 'snarkdown'

export default defineComponent({
  props: {
    formats: {
      type: Object,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const lead = computed(() => {
      if (!props.description) {
        return null
      }
      return snarkdown(props.description)
    })
    return {
      lead,
    }
  },
})
