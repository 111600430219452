<template>
  <InfoBox>
    <header>
      <h3 class="item-title" v-text="adItem?.title"/>
      <BackButton :slug="adItem.type.slug"/>
    </header>

    <div class="item-description" v-html="description"/>
    <AvailableFormats :formats="adItem.formats" v-if="adItem.formats"/>
  </InfoBox>
  <Wrapper :width="wrapperSize.width" :height="wrapperSize.height" center>
    <div class="brand">
      <h2 class="brand-title">Brand name</h2>
      <AdPlaceholder
        :width="200"
        :height="175"
        responsive>
        Logo placeholder
      </AdPlaceholder>
      <h4 class="brand-slogan">Slogan or general category cta</h4>
      <AdPlaceholder
        :width="adSize.width"
        :height="adSize.height"
        responsive>
        Main brand banner
      </AdPlaceholder>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio, earum fugiat. Aut earum error fuga illum
        necessitatibus nulla qui quidem rem sit! Amet atque cupiditate dolores ea explicabo incidunt modi, molestiae
        nam, natus praesentium quisquam quos ratione reprehenderit saepe sequi temporibus, unde. Delectus maiores
        molestias perferendis. Animi architecto aut blanditiis consequuntur corporis deleniti eaque excepturi expedita,
        in ipsum nemo officiis omnis perferendis, praesentium similique. Alias asperiores aspernatur consequuntur
        delectus dignissimos dolorem dolores, eligendi inventore iure laborum minus nam non obcaecati odio quam quidem
        ratione recusandae repellat reprehenderit sapiente sed sequi similique sit tempore, vero voluptate voluptates!
        Delectus illum impedit sapiente!</p>
    </div>

  </Wrapper>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import useItemSizes from '@/composables/useAdItem'

import Wrapper from '@/components/Wrapper.vue'
import InfoBox from '@/components/InfoBox.vue'
import AdPlaceholder from '@/components/AdPlaceholder.vue'
import BackButton from '@/components/BackButton.vue'
import AvailableFormats from '@/components/AvailableFormats.vue'

export default defineComponent({
  components: {
    Wrapper, AdPlaceholder, InfoBox, BackButton, AvailableFormats,
  },
  props: {
    adItem: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const {
      changeSizeTo, wrapperSize, adSize, description,
    } = useItemSizes(props.adItem)

    const showText = ref(false)

    return {
      wrapperSize,
      adSize,
      showText,
      changeSizeTo,
      description,
    }
  },
})
</script>
<style lang="scss" scoped>
.brand {
  @apply text-center;
  max-width: 1170px;
}

.brand-title {
  @apply text-2xl;
}

.brand-slogan {
  @apply text-xl;
}
</style>
