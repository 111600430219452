<template>
  <router-view/>
</template>

<style lang="scss">
@import "~normalize.css";

* {
  box-sizing: border-box;
}

body {
  @apply font-sans text-gray-600;
  background: rgba(14.28, 20.4, 26.520000000000003, 1);
  text-rendering: optimizeLegibility;
  font-smooth: always;
  font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5 {
  @apply text-gray-400;
}

.pattern {
  background-image: radial-gradient(currentColor 1px, transparent 1px);
  background-size: calc(10 * 1px) calc(10 * 1px);
}
</style>
