<template>
  <router-link :to="{ name: 'Type', params: {slug} }" class="back-btn">Back to type</router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
})
</script>
<style lang="scss" scoped>
.back-btn {
  @apply no-underline text-xs text-blue-200 inline-block px-2 py-1 font-bold rounded bg-blue-700;
}
</style>
