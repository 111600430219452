<template>
  <article class="article">
    <div class="thumbnail">
      <div class="category"></div>
    </div>
    <div class="header">
      <div class="comments" v-text="random(0, 100)" />
      <div
        class="title-row"
        v-for="index in calculateNbOfRows()"
        :key="index"
        :style="{'--width': calculateRowLength()}"
      />
      <div class="author" :style="{'--width': `${random(25, 60)}%`}" />
    </div>
  </article>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const random = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min

    const calculateNbOfRows = () => random(2, 4)
    const calculateRowLength = () => `${random(70, 100)}%`

    return {
      random,
      calculateNbOfRows,
      calculateRowLength,
    }
  },
})
</script>
<style lang="scss" scoped>
.article {
  @apply inline-block;
}

.thumbnail {
  @apply bg-gray-300 relative;
  width: 28.125rem;
  height: 15.625rem;
  max-width: 100%;
}

.category {
  @apply w-20 h-6 absolute top-0 left-0 bg-gray-500;
}

.comments {
  @apply w-8 h-8 absolute top-0 right-0 bg-gray-500 inline-flex items-center justify-center font-bold text-xs text-gray-300;
}

.header {
  @apply relative bg-white ml-12 p-4 pr-10 -mt-12;
}

.title-row {
  @apply h-6 bg-gray-400 mb-2;
  width: var(--width)
}

.author {
  @apply h-4 bg-gray-300 mb-2 mt-4;
  width: var(--width)
}
</style>
