<template>
  <InfoBox>
    <header>
      <h3 class="item-title" v-text="adItem?.title"/>
      <BackButton :slug="adItem.type.slug" />
    </header>

    <div class="item-description" v-html="description"/>
    <h4 class="section-heading">Available sizes</h4>
    <ul>
      <li v-for="size in adItem?.sizes" :key="size.id">
        <a
          href="#"
          @click.prevent="changeSizeTo(size)"
          class="size-item-link"
          v-text="size.label"/>
      </li>
    </ul>
    <AvailableFormats :formats="adItem.formats" :description="adItem.formatsLead" v-if="adItem.formats"/>
  </InfoBox>
  <Wrapper :width="wrapperSize.width" :height="wrapperSize.height" center>
    <AdPlaceholder :width="adSize.width" :height="adSize.height"/>
  </Wrapper>
</template>

<script lang="ts">
import {
  defineComponent, onBeforeMount,
} from 'vue'

import useItemSizes from '@/composables/useAdItem'

import AvailableFormats from '@/components/AvailableFormats.vue'
import Wrapper from '@/components/Wrapper.vue'
import InfoBox from '@/components/InfoBox.vue'
import BackButton from '@/components/BackButton.vue'
import AdPlaceholder from '@/components/AdPlaceholder.vue'

export default defineComponent({
  components: {
    AvailableFormats, Wrapper, AdPlaceholder, InfoBox, BackButton,
  },
  props: {
    adItem: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const {
      changeSizeTo, wrapperSize, adSize, getBiggestAdSize, description,
    } = useItemSizes(props.adItem)

    onBeforeMount(() => {
      changeSizeTo(getBiggestAdSize())
    })

    return {
      wrapperSize,
      adSize,
      description,
      changeSizeTo,
    }
  },
})
</script>
