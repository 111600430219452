<template>
  <component
    v-if="component && adItem"
    :is="component"
    :ad-item="adItem"
    :key="$route.fullPath"/>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, computed } from 'vue'
import { useRoute } from 'vue-router'
import get from 'lodash.get'

import useAdItems from '@/composables/useAdItems'
import AdPlaceholder from '@/components/AdPlaceholder.vue'
import FullscreenInterstitial from '@/components/Ads/FullscreenInterstitial.vue'
import MobileFullscreenInterstitial from '@/components/Ads/MobileFullscreenInterstitial.vue'
import BaseAdVariant from '@/components/Ads/BaseAdVariant.vue'
import Screening from '@/components/Ads/Screening.vue'
import GiftsProduct from '@/components/Ads/GiftsProduct.vue'
import GiftsBrandPage from '@/components/Ads/GiftsBrandPage.vue'

export default defineComponent({
  components: {
    BaseAdVariant,
    AdPlaceholder,
    FullscreenInterstitial,
    MobileFullscreenInterstitial,
    Screening,
    GiftsProduct,
    GiftsBrandPage,
    // FullscreenInterstitial: import(/* webpackChunkName: "ad-fullscreen-interstitial" */'@/components/Ads/FullscreenInterstitial.vue'),
  },
  setup() {
    const route = useRoute()
    const { type, item } = route.params

    const { adItem, findOneByType } = useAdItems()
    onBeforeMount(() => findOneByType(type, item))

    const component = computed(() => get(adItem, 'value.component', 'BaseAdVariant') || 'BaseAdVariant')
    return {
      adItem,
      component,
    }
  },
})
</script>
