import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import Type from '../views/Type.vue';
import Item from '../views/Item.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/:slug',
    name: 'Type',
    component: Type,
  },
  {
    path: '/:type/:item',
    name: 'Item',
    component: Item,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
