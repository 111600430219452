<template>
  <router-link
      :to="{ name: 'Item', params: { item: item.slug, type: item.type.slug } }"
      class="list-item"
      v-text="item.label"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
})
</script>
<style lang="scss" scoped>
.list-item {
  @apply px-8 py-3 rounded-lg no-underline font-medium text-xl bg-gray-900;
  @apply text-indigo-100 text-center;
  transition: .5s ease-in-out all;

  &:hover {
    @apply bg-teal-700;
  }
}
</style>
