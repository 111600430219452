<template>
  <div class="articles">
    <Article v-for="index in nb" :key="index"/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Article from '@/components/Article.vue'

export default defineComponent({
  components: {
    Article,
  },
  props: {
    nb: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {}
  },
})
</script>
<style lang="scss" scoped>
.articles {
  @apply bg-white p-12 mx-auto;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 28.125rem 28.125rem;
  justify-content: center;
}
</style>
