
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    center: Boolean,
  },
  setup(props) {
    const style = computed(() => ({
      maxWidth: `${props.width}px`,
      maxHeight: `${props.height}px`,
    }))
    return {
      style,
    }
  },
})
