
import { defineComponent, computed } from 'vue';

export default defineComponent({
  props: {
    type: {
      type: Object,
      required: true,
    },
    odd: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const image = computed(() => `https://ads.antyweb.ably.pl/api/${props.type.thumbnail.formats.small.url}`);

    return {
      image,
    };
  },
});

