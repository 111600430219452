<template>
  <InfoBox>
    <header>
      <h3 class="item-title" v-text="adItem?.title"/>
      <BackButton :slug="adItem.type.slug"/>
    </header>

    <div class="item-description" v-html="description"/>
  </InfoBox>
  <Wrapper :width="wrapperSize.width" :height="wrapperSize.height" center>
    <header class="header">
      <div class="logo">
        <img alt="Vue logo" src="@/assets/logo.svg" width="170">
      </div>
    </header>
    <footer class="footer" v-if="showOptionalBox">
      <div class="cta-wrapper">
        <span class="cta-text">Reklama zniknie za 30s</span>
        <span class="cta-button">Przejdź do Antyweb</span>
      </div>
    </footer>
    <AdPlaceholder :width="adSize.width" :height="adSize.height" responsive/>
  </Wrapper>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

import Wrapper from '@/components/Wrapper.vue'
import InfoBox from '@/components/InfoBox.vue'
import AdPlaceholder from '@/components/AdPlaceholder.vue'
import BackButton from '@/components/BackButton.vue'
import useItemSizes from '@/composables/useAdItem'

export default defineComponent({
  components: {
    Wrapper, AdPlaceholder, InfoBox, BackButton,
  },
  props: {
    adItem: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const {
      changeSizeTo, wrapperSize, adSize, description,
    } = useItemSizes(props.adItem)

    const showOptionalBox = ref(true)
    return {
      wrapperSize,
      adSize,
      showOptionalBox,
      changeSizeTo,
      description,
    }
  },
})
</script>

<style lang="scss" scoped>
.content {
  @apply flex flex-col items-center justify-center;
  height: 100vh;
  width: 100vw;
}

.wrapper {
  @apply relative m-auto;
  height: 100vh;
  width: 100vw;
}

.header {
  @apply absolute top-0 left-0 right-0 flex items-center p-4;
}

.footer {
  @apply absolute bottom-0 left-0 right-0 flex items-center p-4;
}

.logo {
  @apply h-sm bg-black flex items-center justify-center px-6 rounded-lg;
}

.cta-wrapper {
  @apply ml-auto h-sm flex items-center px-2 bg-black rounded-lg text-xs flex-1;
  &--bottom {
    @apply flex-col items-start h-auto py-2 text-sm;
  }
}

.cta-text {
  @apply inline-block mx-2;
}

.cta-button {
  @apply bg-gray-700 inline-flex items-center px-2 font-medium text-white rounded-lg ml-auto;
  height: calc(100% - 2rem);
}

.hide-link {
  @apply no-underline font-medium text-gray-400 inline-block mt-4;
}
</style>
