<template>
  <div class="infoBox" :class="{ 'infoBox--hidden': isHidden }">
    <Hamburger @toggle="handleToggle" class="infoBox__hamburger" active/>
    <slot/>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import Hamburger from '@/components/Hamburger.vue'

export default defineComponent({
  components: { Hamburger },
  setup() {
    const isHidden = ref(false)

    const handleToggle = (state: boolean) => {
      isHidden.value = !state
    }

    return {
      isHidden,
      handleToggle,
    }
  },
})
</script>
<style lang="scss" scoped>
.infoBox {
  @apply fixed bg-gray-800 p-4 rounded right-0 top-0 z-50 m-4 text-sm leading-normal text-gray-500;
  transition: all .3s ease-in-out;
  width: 24rem;

  &--hidden {
    transform: translateX(25rem);
  }

  &__hamburger {
    @apply absolute left-0 top-0;
    transform: translateX(-100%);
  }

  ::v-deep(header) {
    @apply flex items-center justify-between mb-4;
  }

  ::v-deep(.item-title) {
    @apply m-0;
  }

  ::v-deep(.size-item-link) {
    @apply no-underline text-gray-500 font-medium;
  }

}
</style>
