<template>
  <div class="home">
    <header class="header">
      <img alt="Vue logo" src="../assets/logo.svg" width="190">
      <h2>Interactive ad matcher</h2>
    </header>
    <h3>Available ads</h3>
    <p class="lead">Please select ad you're interested in</p>
    <div class="items">
      <TypeLink
          :type="type"
          v-for="(type, index) in types"
          :key="type.id"
          :odd="isOdd(index + 1)"
      />
    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';

import useTypes from '@/composables/useTypes';
import TypeLink from '@/components/TypeLink.vue';
import Footer from '@/components/Footer.vue';

export default defineComponent({
  components: {
    TypeLink,
    Footer,
  },
  setup() {
    const { fetch, types } = useTypes();
    const isOdd = (index: number) => (index % 4) === 2 || (index % 4) === 3;

    onMounted(() => {
      fetch();
    });

    return {
      isOdd,
      types,
    };
  },
});
</script>
<style lang="scss" scoped>

.home {
  @apply text-center;
}

.header {
  @apply flex flex-col items-center py-8 mt-10 justify-center text-gray-800 mb-8;

  background-image: radial-gradient(currentColor 1px, transparent 1px);
  background-size: calc(10 * 1px) calc(10 * 1px);
}

h2 {
  @apply text-lg font-medium mb-0;
}

h3 {
  @apply text-gray-400 text-4xl mb-2;
}

.lead {
  @apply font-medium text-lg;
}

.items {
  @apply py-8 max-w-4xl mx-auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}
</style>
