<template>
  <div class="content">
    <div class="wrapper" :class="{ 'wrapper--center': center }" :style="style">
      <slot/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    center: Boolean,
  },
  setup(props) {
    const style = computed(() => ({
      maxWidth: `${props.width}px`,
      maxHeight: `${props.height}px`,
    }))
    return {
      style,
    }
  },
})
</script>
<style lang="scss" scoped>
.content {
  @apply flex flex-col items-center justify-center;
  height: 100vh;
  width: 100vw;
}

.wrapper {
  @apply relative m-auto;
  height: 100vh;
  width: 100vw;

  &--center {
    @apply flex flex-col items-center justify-center;
  }
}
</style>
