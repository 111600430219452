<template>
  <InfoBox>
    <header>
      <h3 class="item-title" v-text="adItem?.title"/>
      <BackButton :slug="adItem.type.slug"/>
    </header>

    <div class="item-description" v-html="description"/>
    <AvailableFormats :formats="adItem.formats" v-if="adItem.formats"/>
  </InfoBox>
  <Wrapper :width="wrapperSize.width" :height="wrapperSize.height" center>
    <div class="product">
      <AdPlaceholder
        class="product__thumb"
        :width="adSize.width"
        :height="adSize.height"
        responsive/>
      <div class="product__details">
        <h2 class="product-title">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus, soluta.</h2>
        <p class="product-lead">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Aliquid consequatur dignissimos eius in ipsum laudantium nostrum obcaecati quod rerum sapiente. Corporis
          cumque cupiditate debitis delectus deleniti dolor earum eos error hic illum inventore labore laboriosam nisi
          non odit, placeat quae quis veniam. Id maiores non quas. Ab beatae blanditiis consequuntur dolor doloremque
        </p>
        <span class="toggle-text" v-if="!showText" @click="showText = true">read more</span>
        <div class="more-info" v-if="showText">
          <p class="product-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet corporis,
            debitis, deleniti dolor dolorum, ea eligendi est eveniet expedita libero maiores natus numquam quae
            quibusdam sit veniam voluptates voluptatibus voluptatum?</p>
          <ul>
            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa, dignissimos.</li>
            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa, dignissimos.</li>
            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa, dignissimos.</li>
            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa, dignissimos.</li>
          </ul>
          <p class="product-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet corporis,
            debitis, deleniti dolor dolorum, ea eligendi est eveniet expedita libero maiores natus numquam quae
            quibusdam sit veniam voluptates voluptatibus voluptatum?</p>
          <p class="product-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet corporis,
            debitis, deleniti dolor dolorum, ea eligendi est eveniet expedita libero maiores natus numquam quae
            quibusdam sit veniam voluptates voluptatibus voluptatum?</p>
        </div>
      </div>
      <div class="product__price">
        <span class="price-from">starts from</span>
        <strong class="price">2000 <sup>PLN</sup></strong>
        <div class="button">Check price</div>
      </div>
    </div>

  </Wrapper>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import useItemSizes from '@/composables/useAdItem'

import Wrapper from '@/components/Wrapper.vue'
import InfoBox from '@/components/InfoBox.vue'
import AdPlaceholder from '@/components/AdPlaceholder.vue'
import BackButton from '@/components/BackButton.vue'
import AvailableFormats from '@/components/AvailableFormats.vue'

export default defineComponent({
  components: {
    Wrapper, AdPlaceholder, InfoBox, BackButton, AvailableFormats,
  },
  props: {
    adItem: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const {
      changeSizeTo, wrapperSize, adSize, description,
    } = useItemSizes(props.adItem)

    const showText = ref(false)

    return {
      wrapperSize,
      adSize,
      showText,
      changeSizeTo,
      description,
    }
  },
})
</script>
<style lang="scss" scoped>
.product {
  @apply mx-auto flex items-center;
  width: 1170px;
  max-width: 100%;

  &__thumb {
    @apply flex-none mr-4;
  }

  &__details {
    @apply mr-8 leading-tight relative;
  }

  &__price {
    @apply flex-none;
  }
}

.more-info {
  @apply absolute;
}
.toggle-text {
  @apply font-medium underline text-sm text-gray-500 cursor-pointer;
}

.price {
  @apply text-4xl font-bold block;
}

.button {
  @apply bg-teal-400 text-white text-base font-medium py-3 text-center uppercase mt-4;
}
</style>
