<template>
  <div class="ad-placeholder" :style="style" :class="{ 'pattern': !isFilled, 'ad-placeholder--responsive': responsive }">
    <div class="ad-placeholder__label" v-text="label" v-if="!isFilled"/>
    <div class="ad-placeholder__label" v-if="$slots.default"><slot/></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'

export default defineComponent({
  props: {
    height: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    responsive: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isFilled = ref(false)

    const style = computed(() => ({
      width: `${props.width}px`,
      height: `${props.height}px`,
    }))

    const label = computed(() => `${props.width}x${props.height}`)

    return {
      isFilled,
      style,
      label,
    }
  },
})
</script>
<style lang="scss" scoped>
.ad-placeholder {
  @apply text-indigo-800 mx-auto flex items-center justify-center bg-white flex-col;

  &--responsive {
    max-width: 100%;
    max-height: 100%;
  }

  &__label {
    @apply inline-block px-4 py-2 rounded-lg bg-white font-bold;
  }
}
</style>
