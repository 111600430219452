<template>
  <h4>Available formats</h4>
  <div v-if="lead" v-html="lead" class="lead" />
  <ul class="formats">
    <li v-for="format in formats" :key="format" class="format">
      <strong v-text="format.name" class="format__name"/>
      <p v-text="format.description" class="format__description"/>
      <p class="format__assets">
        <strong>Assets:</strong> {{ format.assets }}
      </p>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import snarkdown from 'snarkdown'

export default defineComponent({
  props: {
    formats: {
      type: Object,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const lead = computed(() => {
      if (!props.description) {
        return null
      }
      return snarkdown(props.description)
    })
    return {
      lead,
    }
  },
})
</script>
<style lang="scss" scoped>
.formats {
  @apply list-none pl-0;

  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 1rem

}
.format {
  @apply text-xs mb-0;

  &__name {

  }

  &__description {
    @apply m-0 mb-2;

    font-size: 0.625rem;
  }

  &__assets {
    @apply m-0;

    font-size: 0.625rem;
  }
}
.lead {
  @apply text-xs;
}
</style>
