
import { defineComponent, ref } from 'vue'
import useItemSizes from '@/composables/useAdItem'

import Wrapper from '@/components/Wrapper.vue'
import InfoBox from '@/components/InfoBox.vue'
import AdPlaceholder from '@/components/AdPlaceholder.vue'
import BackButton from '@/components/BackButton.vue'
import AvailableFormats from '@/components/AvailableFormats.vue'

export default defineComponent({
  components: {
    Wrapper, AdPlaceholder, InfoBox, BackButton, AvailableFormats,
  },
  props: {
    adItem: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const {
      changeSizeTo, wrapperSize, adSize, description,
    } = useItemSizes(props.adItem)

    const showText = ref(false)

    return {
      wrapperSize,
      adSize,
      showText,
      changeSizeTo,
      description,
    }
  },
})
