
import {
  defineComponent, onBeforeMount,
} from 'vue'

import useItemSizes from '@/composables/useAdItem'

import AvailableFormats from '@/components/AvailableFormats.vue'
import Wrapper from '@/components/Wrapper.vue'
import InfoBox from '@/components/InfoBox.vue'
import BackButton from '@/components/BackButton.vue'
import AdPlaceholder from '@/components/AdPlaceholder.vue'

export default defineComponent({
  components: {
    AvailableFormats, Wrapper, AdPlaceholder, InfoBox, BackButton,
  },
  props: {
    adItem: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const {
      changeSizeTo, wrapperSize, adSize, getBiggestAdSize, description,
    } = useItemSizes(props.adItem)

    onBeforeMount(() => {
      changeSizeTo(getBiggestAdSize())
    })

    return {
      wrapperSize,
      adSize,
      description,
      changeSizeTo,
    }
  },
})
