
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const random = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min

    const calculateNbOfRows = () => random(2, 4)
    const calculateRowLength = () => `${random(70, 100)}%`

    return {
      random,
      calculateNbOfRows,
      calculateRowLength,
    }
  },
})
