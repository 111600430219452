
import { defineComponent, ref } from 'vue'

export default defineComponent({
  emits: ['toggle'],
  props: {
    active: Boolean,
  },
  setup(props, { emit }) {
    const isActive = ref(props.active)
    const toggle = () => {
      isActive.value = !isActive.value
      emit('toggle', isActive.value)
    }
    return {
      isActive,
      toggle,
    }
  },
})
